<div class="heightAll userBar overflow" id="userBar">
  <div class="colPadding">
    <h2>Användarkonto</h2>
    <ul class="ListNav">
      <li routerLinkActive="activeListNav">
        <a [routerLink]="['/account', 'settings']">
          <i class="fa fa-cog fa-fw"></i>
          Inställningar
        </a>
      </li>
      <li routerLinkActive="activeListNav">
        <a [routerLink]="['/account', 'change-password']">
          <i class="fa fa-lock fa-fw"></i>
          Byt lösenord
        </a>
      </li>
    </ul>
  </div>
</div>
