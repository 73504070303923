import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { PaginationRequestWithFieldFilter } from 'api/admin/requests';
import { GetHotkeysResponse } from 'api/responses';
import { GetHotkeysResult } from 'api/results';

@Injectable()
export class HotkeyInfoService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/hotkeys', errorService);
  }

  getHotkeyInfo(request: PaginationRequestWithFieldFilter): Observable<GetHotkeysResponse> {
    return this.put<GetHotkeysResponse, GetHotkeysResult>(
      '', request
    ).pipe(
      map(response => new GetHotkeysResponse(response)),
      catchError(error => this.onError(new GetHotkeysResponse(error)))
    );
  }
}
