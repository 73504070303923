<div class="footer-row-ecus">
  <div class="uk-text-center uk-align-center">
    <img
      class="footer-logo uk-margin-small-right"
      src="images/ecus_logo_vit.png"
      alt="ecus-logo"
    />

    <a
      class="footer-btn uk-margin-small-right"
      href="https://www.ecus.se/sv/kontakt/kontakta-oss"
      target="_blank"
      >Kontakta Ecus</a
    >
    <a class="footer-btn" [routerLink]="['/about']">Om ECOM</a>
  </div>
</div>
