<div class="ecus-login-bgr image-bgr">
  <div class="login-container">
    <div class="login-pos">
      <form
        [formGroup]="form"
        (ngSubmit)="resetPassword()"
        name="ResetPassword_Form"
        class="form-signin form-signin-white uk-form uk-form-horizontal"
      >
        <h1 class="uk-text-center"><strong>ECOM</strong></h1>
        <p class="uk-text-center">Ecus Customs Order Management</p>

        <h3 class="form-signin-heading">
          {{'account.user.resetPassword.title' | translate}}
        </h3>

        <ng-container *ngIf="!success">
          <uikit-form-element
            type="password"
            name="newPassword"
            size="md"
            [control]="newPasswordControl"
            [label]="'account.user.resetPassword.model.newPassword' | translate"
            [placeholder]="'account.user.resetPassword.model.newPassword.placeholder' | translate"
          >
          </uikit-form-element>

          <uikit-form-element
            type="password"
            name="confirmPassword"
            size="md"
            [control]="confirmPasswordControl"
            [label]="'account.user.resetPassword.model.confirmPassword' | translate"
            [placeholder]="'account.user.resetPassword.model.confirmPassword.placeholder' | translate"
          >
          </uikit-form-element>

          <button
            class="uk-button uk-button-primary pull-right"
            name="submit"
            value="ResetPassword"
            type="submit"
            [innerHTML]="buttonText | async"
          ></button>

          <div class="error-message top-margin-1" *ngIf="error != null">
            <span>{{error | async}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="error==null && success">
          <p>
            {{'account.user.resetPassword.success' | translate}}
            <a routerLink="/login"
              >{{'account.user.resetPassword.successLink' | translate}}</a
            >
          </p>
        </ng-container>
      </form>
    </div>
  </div>
</div>

<welcome-footer></welcome-footer>
