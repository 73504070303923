import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from 'core/authentication.service';
import { AccountService } from './account.service';
import { GetInvitationResult, CreateUserResult } from 'api/results';
import { CreateUserRequest } from 'api/requests';
import { PasswordStrengthValidator } from './validators/password-strength.validator';
import { PasswordMinLengthValidator } from './validators/password-min-length.validator';
import { PasswordConfirmValidator } from './validators/password-confirm.validator';
import { AbstractControlWithWarnings } from 'shared/AbstractControlWithWarnings';

@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html'
})
export class CreateUserComponent implements OnInit {
  form: UntypedFormGroup;

  loading: boolean;
  loaded = false;
  error: string;
  invitationId: string;
  companyName: string;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      phoneNumber: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, [
        Validators.required,
        PasswordMinLengthValidator,
        PasswordStrengthValidator
      ]),
      confirmPassword: new UntypedFormControl(null)
    });
    this.form
      .get('confirmPassword')
      .setValidators([
        Validators.required,
        PasswordConfirmValidator(this.form.get('password'))
      ]);
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('ecom-loaded');
    this.loading = true;

    this.invitationId = this.route.snapshot.params['invitationId'];

    this.accountService.getInvitation(this.invitationId).subscribe(
      response => {
        this.loading = false;
        this.loaded = true;
        this.companyName = response.companyName;
        this.error = null;
      },
      error => {
        this.loading = false;
        switch (error.result) {
          case GetInvitationResult.InvalidInvitation:
            this.error = 'Ogiltig inbjudan!';
            break;
          case GetInvitationResult.InvitationExpired:
            this.error = 'Inbjudans giltighetstid har gått ut.';
            break;
          default:
            this.error = 'Fel vid hämtning av inbjudan!';
            break;
        }
      }
    );
  }

  create(): void {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const request = new CreateUserRequest();
    request.invitationId = this.invitationId;

    request.name = this.form.value.name;
    request.phoneNumber = this.form.value.phoneNumber;
    request.password = this.form.value.password;

    this.loading = true;
    this.accountService.createUser(request).subscribe(
      response => {
        this.loading = false;
        this.error = null;
        this.authenticationService
          .logIn(response.email, request.password, false)
          .subscribe(
            _data => {
              this.router.navigate(['/']);
            },
            _error => {
              this.router.navigate(['/login']);
            }
          );
      },
      error => {
        this.loading = false;
        if (error.error !== '') this.error = error.error;
        else this.error = CreateUserResult[error.result];
      }
    );
  }

  hasError(form: NgForm, key: string): boolean {
    const control = form.controls[key];

    if (!control) return false;

    return (
      (control.dirty || control.touched || this.submitted) &&
      control.errors != null
    );
  }

  get nameControl(): AbstractControlWithWarnings {
    return this.form.get('name') as AbstractControlWithWarnings;
  }

  get phoneNumberControl(): AbstractControlWithWarnings {
    return this.form.get('phoneNumber') as AbstractControlWithWarnings;
  }

  get passwordControl(): AbstractControlWithWarnings {
    return this.form.get('password') as AbstractControlWithWarnings;
  }

  get confirmPasswordControl(): AbstractControlWithWarnings {
    return this.form.get('confirmPassword') as AbstractControlWithWarnings;
  }
}
