import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { AuthenticationService } from 'core/authentication.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  error: string;
  private route: ActivatedRouteSnapshot;
  public isSubmitting = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    titleService: Title
  ) {
    this.route = router.routerState.snapshot.root;
    titleService.setTitle('Inloggning - Ecus ECOM');
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('ecom-loaded');

    this.activeRoute.queryParams.subscribe(params => {
      if (params['err'] && params['err'] === '500') {
        this.isSubmitting = false;
        this.error = 'Tillfällig server-fel';
      }
    });
  }

  logIn(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.error = null;
    this.isSubmitting = true;
    this.authenticationService
      .logIn(form.value.email, form.value.password, form.value.rememberMe)
      .subscribe(
        _data => {
          if (
            this.route.queryParams &&
            this.route.queryParams['returnTo'] &&
            this.route.queryParams['returnTo'] !== ''
          )
            this.router.navigateByUrl(this.route.queryParams['returnTo']);
          else this.router.navigate(['/']);
        },
        error => {
          this.isSubmitting = false;
          if (error === 'incorrect_username_password') {
            this.error = 'Felaktigt användarnamn eller lösenord!';
          } else if (error === 'user_lockout') {
            this.error = 'För många misslyckade inloggningsförsök!';
          } else {
            this.error = 'Fel vid inloggning!';
          }
        },
        () => {
          this.isSubmitting = false;
        }
      );
  }

  public get buttonText(): string {
    if (this.isSubmitting) return '<div uk-spinner>Loggar in</div>';

    return 'Logga in';
  }
}
