import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl
} from '@angular/forms';

import * as XRegExp from 'xregexp';

import { ErrorService } from 'core/error.service';
import { ContentTitleService } from 'app/content-title.service';
import { AccountService } from 'account/account.service';
import { Translations } from 'app/translations';
import { ChangePasswordRequest } from 'api/requests';
import { ChangePasswordResult } from 'api/results';
import { Collapse } from 'shared/animations/collapse.animation';
import { GenericApiResponse } from 'api/responses';
import { PASSWORD_VALIDATION_PATTERN } from 'app/app-constants';
import { AbstractControlWithWarnings } from 'shared/AbstractControlWithWarnings';

@Component({
  selector: '[change-password]',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.css'],
  animations: [Collapse(300)]
})
export class ChangePasswordPage implements OnInit {
  title = 'Byt lösenord';
  success = false;

  submitted = false;
  submitting = false;

  public form: UntypedFormGroup;
  public get oldPassword() {
    return this.form.get('oldPassword') as AbstractControlWithWarnings;
  }
  public get newPassword() {
    return this.form.get('newPassword') as AbstractControlWithWarnings;
  }
  public get confirmPassword() {
    return this.form.get('confirmPassword') as AbstractControlWithWarnings;
  }

  constructor(
    private contentService: ContentTitleService,
    private errorService: ErrorService,
    private accountService: AccountService,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group(
      {
        oldPassword: [null, Validators.required],
        newPassword: [
          null,
          [Validators.required, Validators.minLength(6), this.passwordValidator]
        ],
        confirmPassword: [
          null,
          [Validators.required, Validators.minLength(6), this.passwordValidator]
        ]
      },
      { validators: [this.checkPasswordMatches] }
    );
  }

  ngOnInit() {
    this.contentService.setTitle(this.title);
  }

  changePassword() {
    this.submitted = true;
    this.submitting = true;

    if (!this.form.valid) {
      this.submitting = false;
      return;
    }

    const request = new ChangePasswordRequest();
    request.oldPassword = this.oldPassword.value;
    request.newPassword = this.newPassword.value;
    request.confirmPassword = this.confirmPassword.value;

    this.accountService.changePassword(request).subscribe(
      response => {
        if (response.result === ChangePasswordResult.Success) {
          this.errorService.next(null);
          this.submitting = false;
          this.success = true;

          this.form.reset();
        } else {
          this.handleError(response);
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  handleError(response: GenericApiResponse<ChangePasswordResult>) {
    this.errorService.next(null);
    this.submitting = false;

    let errorText = '';

    switch (response.result) {
      case ChangePasswordResult.InvalidOldPassword:
        errorText = Translations.ChangePassword.IncorrectOldPassword;
        break;
      case ChangePasswordResult.InvalidModelState:
        errorText = Translations.ChangePassword.InvalidModelState;
        break;
      case ChangePasswordResult.PasswordValidationFailed:
        errorText = Translations.ChangePassword.PasswordValidationFailed;
        break;
      case ChangePasswordResult.PasswordRequiresLower:
        errorText = Translations.ChangePassword.PasswordRequiresLower;
        break;
      case ChangePasswordResult.PasswordRequiresUpper:
        errorText = Translations.ChangePassword.PasswordRequiresUpper;
        break;
      case ChangePasswordResult.PasswordRequiresNonAlphanumeric:
        errorText = Translations.ChangePassword.PasswordRequiresNonAlphanumeric;
        break;
      case ChangePasswordResult.PasswordRequiresUniqueChars:
        errorText = Translations.ChangePassword.PasswordRequiresUniqueChars;
        break;
      case ChangePasswordResult.PasswordTooShort:
        errorText = Translations.ChangePassword.PasswordTooShort;
        break;
      default:
        errorText = Translations.ChangePassword.DefaultError;
        break;
    }

    this.errorService.next({
      title: 'Fel',
      message: errorText
    });
  }

  private checkPasswordMatches(group: UntypedFormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  private passwordValidator(field: UntypedFormControl) {
    const value: string = field.value;
    if (!value) return null;

    return value.match(PASSWORD_VALIDATION_PATTERN) ? null : { pattern: true };
  }
}
