import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HotkeyInfoService } from 'account/services/hotkey-info-service';
import { PaginationRequestWithFieldFilter } from 'api/admin';
import { GetHotkeysResponse } from 'api/responses';
import { HotkeyInfoTableColumn } from 'account/models/table-columns/hotkey-info-table-column';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: '[ecom-hotkey-list]',
  templateUrl: './hotkey-list.page.html',
  styleUrls: ['./hotkey-list.page.css']
})
export class HotkeyListPage implements OnInit, AfterViewInit {
  request$: Subscription;
  hotkeysResponse: GetHotkeysResponse;

  columns: HotkeyInfoTableColumn[];

  constructor(private hotkeyInfoService: HotkeyInfoService) {}

  ngOnInit(): void {
    const request: PaginationRequestWithFieldFilter = {
      sortingField: 'name',
      sortingDescending: false,
      page: 1,
      pageSize: 25,
      filters: []
    };
    this.setRequest(request);
  }

  ngAfterViewInit(): void {
    this.columns = this.setColumns();
  }

  loadHotkeyInfo(event: TableLazyLoadEvent): void {
    const filters = [];
    this.setFilters(filters, event);
  }

  setFilters(
    filters: { field: string; value: any }[],
    event: TableLazyLoadEvent
  ) {
    const request: PaginationRequestWithFieldFilter = {
      filters: filters,
      sortingField: event.sortField as string,
      sortingDescending: event.sortOrder === -1,
      page: (event.first || 0) / (event.rows || 1) + 1,
      pageSize: 25
    };

    if (request != null) {
      this.setRequest(request);
    }
  }

  private setRequest(request: PaginationRequestWithFieldFilter) {
    this.request$ = this.hotkeyInfoService
      .getHotkeyInfo(request)
      .subscribe(res => {
        this.hotkeysResponse = res;
      });
  }

  private setColumns(): HotkeyInfoTableColumn[] {
    return [
      {
        header: 'account.hotkey.mappedKey',
        field: 'mappedKey',
        filterType: 'text'
      },
      {
        header: 'account.hotkey.name',
        field: 'name',
        filterType: 'text'
      },
      {
        header: 'account.hotkey.description',
        field: 'description',
        filterType: 'text'
      }
    ];
  }
}
