<div class="ecus-login-bgr image-bgr">
  <div class="uk-container uk-height-1-1">
    <div
      class="uk-flex-middle uk-flex-center uk-grid-collapse uk-width-1-1 uk-height-1-1"
      uk-grid
    >
      <div class="uk-width-2-3@s uk-width-2-5@m uk-text-center">
        <form
          #form="ngForm"
          (ngSubmit)="logIn(form)"
          name="Login_Form"
          class="uk-form form-signin form-signin-white"
        >
          <h1 class="uk-text-center"><strong>ECOM</strong></h1>
          <p class="uk-text-center">Ecus Customs Order Management</p>

          <h3 class="form-signin-heading"><strong>INLOGGNING</strong></h3>

          <div class="uk-form-row uk-margin">
            <input
              type="text"
              class="uk-input uk-width-1-1"
              placeholder="E-postadress"
              required=""
              autofocus
              name="email"
              autocomplete="email"
              ngModel
            />
          </div>
          <div class="uk-form-row">
            <input
              type="password"
              class="uk-input uk-width-1-1"
              placeholder="Lösenord"
              required=""
              name="password"
              autocomplete="current-password"
              ngModel
            />
          </div>
          <div class="uk-form-row uk-margin">
            <label class="checkbox">
              <input
                type="checkbox"
                class="form-check-input"
                value="remember-me"
                id="rememberMe"
                name="rememberMe"
                ngModel
              />
              Kom ihåg mig
            </label>
          </div>

          <div class="uk-form-row">
            <button
              class="uk-button uk-button-primary uk-button-large uk-width-1-1"
              [disabled]="isSubmitting"
              name="Submit"
              type="submit"
              [innerHTML]="buttonText"
            ></button>
          </div>

          <div
            class="error-message uk-margin-top uk-text-danger"
            *ngIf="error != null"
          >
            <span>{{ error }}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<welcome-footer></welcome-footer>
