import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountService } from './account.service';
import { CreateUserComponent } from './create-user.component';
import { LoginComponent } from './login.component';
import { WelcomeFooterComponent } from './welcome-footer.component';
import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
import { SharedModule } from 'shared/shared.module';
import { SettingsPage, ResetPasswordPage, ChangePasswordPage, HotkeyListPage } from './pages';
import { HotkeyInfoService } from './services/hotkey-info-service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    AccountSidebarComponent,
    CreateUserComponent,
    SettingsPage,
    LoginComponent,
    WelcomeFooterComponent,
    ResetPasswordPage,
    ChangePasswordPage,
    HotkeyListPage
  ],
  providers: [AccountService, HotkeyInfoService]
})
export class AccountModule {}
