<div class="colPadding">
  <h2>
    <i class="fa fa-clock-o fa-fw"></i>{{ 'account.hotkey.title' | translate }}
  </h2>

  <div *ngIf="hotkeysResponse" class="uk-margin-medium-top">
    <p-table
      #caseTable
      [lazyLoadOnInit]="false"
      [columns]="columns"
      [paginator]="true"
      [rows]="hotkeysResponse.pageSize"
      [value]="hotkeysResponse.hotkeys"
      [scrollable]="true"
      scrollDirection="horizontal"
      *ngIf="request$; let response"
      [lazy]="true"
      (onLazyLoad)="loadHotkeyInfo($event)"
      [totalRecords]="hotkeysResponse.totalCount"
      [rowHover]="true"
      sortField="name"
      [sortOrder]="1"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col
            *ngFor="let col of columns"
            [ngClass]="['overview-column-' + col.field]"
          />
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="col.disableSort"
          >
            <ng-container *ngIf="col.icon">
              <i [class]="'fa fa-' + col.icon"></i>
            </ng-container>
            {{ col.header | translate }}
            <p-sortIcon
              [field]="col.field"
              *ngIf="!col.disableSort"
            ></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-hotkey let-columns="columns">
        <tr [pSelectableRow]>
          <td
            *ngFor="let col of columns"
            [class.sticky-overview-column]="col.sticky"
          >
            <ng-container *ngIf="!col.template && !col.displayValue">
              {{ hotkey[col.field] }}
            </ng-container>
            <ng-container *ngIf="!col.template && col.displayValue">
              {{ col.displayValue(hotkey) }}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
