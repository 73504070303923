import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ErrorService } from 'core/error.service';
import { ContentTitleService } from 'app/content-title.service';
import { AccountService } from 'account/account.service';

@Component({
  selector: '[account-settings]',
  templateUrl: './settings.page.html'
})
export class SettingsPage implements OnInit {
  title = 'Mina inställningar';
  error: string;
  success = false;

  submitted = false;
  submitting = false;

  constructor(
    private contentService: ContentTitleService,
    private errorService: ErrorService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.contentService.setTitle(this.title);
  }

  hasError(form: NgForm, key: string): boolean {
    const control = form.controls[key];

    if (!control) return false;

    return (
      (control.dirty || control.touched || this.submitted) &&
      control.errors != null
    );
  }

  get buttonText(): string {
    if (this.submitting) return "Bytar <i class='fa fa-spinner fa-spin'></i>";

    return 'Byt lösenord';
  }
}
