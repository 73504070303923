import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordConfirmValidator = function (
  toConfirm: AbstractControl
): (control: AbstractControl) => ValidationErrors | null {
  return function (control: AbstractControl) {
    return toConfirm.value === control.value
      ? null
      : { passwordConfirm: 'The passwords do not match' };
  };
};
