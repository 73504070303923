import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NgForm,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { ResetPasswordResult } from 'api/results';
import { AccountService } from 'account/account.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ResetPasswordRequest } from 'api/requests/reset-password-request';
import { PasswordStrengthValidator } from 'account/validators/password-strength.validator';
import { PasswordConfirmValidator } from 'account/validators/password-confirm.validator';
import { PasswordMinLengthValidator } from 'account/validators/password-min-length.validator';

@Component({
  selector: 'login',
  templateUrl: './reset-password.page.html'
})
export class ResetPasswordPage implements OnInit {
  error: Observable<string>;
  success: boolean;
  resetToken: string;
  userId: string;

  submitted = false;
  submitting = false;
  showPasswordHelp = false;

  form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    titleService: Title,
    private translateService: TranslateService
  ) {
    this.translateService
      .get('account.user.resetPassword.title')
      .toPromise()
      .then(text => {
        titleService.setTitle(text + ' - Ecus ECOM');
      });

    var passwordField = new UntypedFormControl(null, [
      Validators.required,
      PasswordMinLengthValidator,
      PasswordStrengthValidator
    ]);

    var confirmPasswordField = new UntypedFormControl(null, [
      Validators.required,
      PasswordConfirmValidator(passwordField)
    ]);

    this.form = new UntypedFormGroup({
      newPassword: passwordField,
      confirmPassword: confirmPasswordField
    });
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('ecom-loaded');

    this.resetToken = this.route.snapshot.params['resetToken'];
    this.userId = this.route.snapshot.params['userId'];
    this.success = false;
  }

  resetPassword() {
    this.submitted = true;
    this.submitting = true;

    if (!this.form.valid) {
      this.submitting = false;
      return;
    }

    const request = new ResetPasswordRequest();
    request.userId = this.userId;
    request.token = this.resetToken;
    request.newPassword = this.form.value.newPassword;
    request.confirmPassword = this.form.value.confirmPassword;

    this.accountService.resetPassword(request).subscribe(
      _data => {
        this.submitting = false;
        this.error = null;
        this.success = true;
      },
      error => {
        this.submitting = false;
        switch (error.result) {
          case ResetPasswordResult.InvalidToken:
            this.error = this.translateService.get(
              'account.user.resetPassword.tokenInvalid'
            );
            break;
          default:
            this.error = this.translateService.get(
              'account.user.resetPassword.unknownError'
            );
            break;
        }
      }
    );
  }

  hasError(form: NgForm, key: string): boolean {
    const control = form.controls[key];

    if (!control) return false;

    return (
      (control.dirty || control.touched || this.submitted) &&
      control.errors != null
    );
  }

  get buttonText(): Observable<string> {
    if (this.submitting)
      return this.translateService
        .get('account.user.resetPassword.resetting')
        .pipe(map(text => text + ' <i class="fa fa-spinner fa-spin"></i>'));

    return this.translateService.get('account.user.resetPassword.reset');
  }

  get newPasswordControl(): UntypedFormControl {
    return this.form.get('newPassword') as UntypedFormControl;
  }

  get confirmPasswordControl(): UntypedFormControl {
    return this.form.get('confirmPassword') as UntypedFormControl;
  }
}
