<div class="ecus-login-bgr image-bgr">
  <div class="container login-container">
    <div class="row login-pos">
      <div class="col-md-4 col-md-offset-4 col-sm-12 text-center">
        <form
          #createUserForm="ngForm"
          (ngSubmit)="create()"
          novalidate
          class="form-signin form-signin-white uk-form uk-form-horizontal"
        >
          <div class="col-xs-12">
            <h1 class="text-center"><strong>ECOM</strong></h1>
            <p class="text-center">Ecus Customs Order Management</p>
          </div>

          <h3 class="form-signin-heading">
            {{ 'createUser.title' | translate }}
          </h3>

          <div *ngIf="loaded">
            <h4>{{ companyName }}</h4>

            <uikit-form-element
              [control]="nameControl"
              name="name"
              [label]="'createUser.model.name' | translate"
              [placeholder]="'createUser.model.name.placeholder' | translate"
              [submitted]="submitted"
              size="lg"
            ></uikit-form-element>
            <uikit-form-element
              [control]="phoneNumberControl"
              name="phoneNumber"
              [label]="'createUser.model.phoneNumber' | translate"
              [placeholder]="
                'createUser.model.phoneNumber.placeholder' | translate
              "
              [submitted]="submitted"
              size="lg"
            ></uikit-form-element>
            <uikit-form-element
              type="password"
              [control]="passwordControl"
              name="password"
              [label]="
                'account.user.resetPassword.model.newPassword' | translate
              "
              [placeholder]="
                'account.user.resetPassword.model.newPassword.placeholder'
                  | translate
              "
              [submitted]="submitted"
              size="lg"
            ></uikit-form-element>
            <uikit-form-element
              type="password"
              [control]="confirmPasswordControl"
              name="confirmPassword"
              [label]="
                'account.user.resetPassword.model.confirmPassword' | translate
              "
              [placeholder]="
                'account.user.resetPassword.model.confirmPassword.placeholder'
                  | translate
              "
              [submitted]="submitted"
              size="lg"
            ></uikit-form-element>

            <p>&nbsp;</p>

            <div class="form-group">
              <button
                class="uk-button uk-button-primary uk-button-large uk-width-1-1"
                name="submit"
                type="submit"
                [disabled]="loading"
              >
                <div *ngIf="loading" uk-spinner>
                  {{ 'createUser.submitting' | translate }}
                </div>
                <div *ngIf="!loading">
                  {{ 'createUser.submit' | translate }}
                </div>
              </button>
            </div>
          </div>

          <div
            class="error-message top-margin-1"
            *ngIf="!loading && error != null"
          >
            <span>{{ error }}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<welcome-footer></welcome-footer>
