<div class="heightAll userBar overflow" id="userBar">
  <div account-sidebar></div>
</div>

<div class="heightAll overflow mainBar">
  <div class="colPadding">
    <div class="uk-width-1-1">
      <h2 *ngIf="title">{{title}}</h2>
      Inget här än...
    </div>
  </div>
</div>
