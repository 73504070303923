<div class="heightAll userBar overflow" id="userBar">
  <div account-sidebar></div>
</div>

<div class="heightAll overflow mainBar">
  <div class="colPadding">
    <div class="uk-width-1-1">
      <h2 *ngIf="title">{{title}}</h2>
      <form
        [formGroup]="form"
        [@collapse]
        *ngIf="!success"
        class="uk-form change-password-form uk-form-horizontal"
      >
        <uikit-form-element
          type="password"
          [control]="oldPassword"
          name="oldPassword"
          [label]="
        'account.user.changePassword.model.oldPassword' | translate
      "
          [placeholder]="
        'account.user.changePassword.model.oldPassword.placeholder'
          | translate
      "
          [submitted]="submitted"
          size="lg"
        ></uikit-form-element>

        <uikit-form-element
          type="password"
          [control]="newPassword"
          name="newPassword"
          [label]="
        'account.user.resetPassword.model.newPassword' | translate
      "
          [placeholder]="
        'account.user.resetPassword.model.newPassword.placeholder'
          | translate
      "
          [submitted]="submitted"
          size="lg"
        ></uikit-form-element>
        <uikit-form-element
          type="password"
          [control]="confirmPassword"
          name="confirmPassword"
          [label]="
        'account.user.resetPassword.model.confirmPassword' | translate
      "
          [placeholder]="
        'account.user.resetPassword.model.confirmPassword.placeholder'
          | translate
      "
          [submitted]="submitted"
          size="lg"
        ></uikit-form-element>

        <div class="uk-width-6-10 uk-push-4-10 uk-margin-top">
          <button
            class="uk-button uk-button-primary"
            (click)="changePassword()"
            [disabled]="!form.valid || submitting"
          >
            <i class="fa fa-lock uk-icon-justify"></i>
            Byt lösenord
            <div *ngIf="submitting" uk-spinner></div>
          </button>
        </div>
      </form>
      <div [@collapse] *ngIf="success">Ditt lösenord har blivit bytt!</div>

      <ul>
        <li>{{'account.password.requirements.length'|translate}}</li>
        <li>{{'account.password.requirements.nonAlphanumeric'|translate}}</li>
        <li>{{'account.password.requirements.numeric'|translate}}</li>
        <li>{{'account.password.requirements.lowercase'|translate}}</li>
        <li>{{'account.password.requirements.uppercase'|translate}}</li>
      </ul>
    </div>
  </div>
</div>
