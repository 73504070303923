import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import {
  CreateUserResult,
  ChangePasswordResult,
  GetInvitationResult,
  ResetPasswordResult
} from 'api/results';
import { GetInvitationResponse } from 'api/responses';
import { GenericApiResponse, CreateUserResponse } from 'api/responses';
import {
  CreateUserRequest,
  ResetPasswordRequest,
  ChangePasswordRequest
} from 'api/requests';

@Injectable()
export class AccountService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, '', errorService);
  }

  getInvitation(id: string): Observable<GetInvitationResponse> {
    return this.get<GetInvitationResponse, GetInvitationResult>(
      `api/invitations/${id}`
    ).pipe(
      map(response => new GetInvitationResponse(response)),
      catchError(error => this.onError(new GetInvitationResponse(error)))
    );
  }

  createUser(request: CreateUserRequest): Observable<CreateUserResponse> {
    return this.post<CreateUserResponse, CreateUserResult>(
      'api/createuser/',
      request
    ).pipe(
      map(response => new CreateUserResponse(response)),
      catchError(error => this.onError(new CreateUserResponse(error)))
    );
  }

  resetPassword(
    request: ResetPasswordRequest
  ): Observable<GenericApiResponse<ResetPasswordResult>> {
    return this.post<
      GenericApiResponse<ResetPasswordResult>,
      ResetPasswordResult
    >('api/account/resetpassword', request).pipe(
      map(response => new GenericApiResponse(response)),
      catchError(error => this.onError(new GenericApiResponse(error)))
    );
  }

  changePassword(
    request: ChangePasswordRequest
  ): Observable<GenericApiResponse<ChangePasswordResult>> {
    return this.post<
      GenericApiResponse<ChangePasswordResult>,
      ChangePasswordResult
    >('api/account/changepassword', request).pipe(
      map(response => new GenericApiResponse(response)),
      catchError(error => this.onError(new GenericApiResponse(error)))
    );
  }
}
